.launch {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
}

// button {
//   margin-top: 10px;
//   background-color: #1DF9BA;
//   border-radius: 8px;
//   color:black;
//   cursor: pointer;
//   padding: 5px;
// }

button {
  margin-top: 10px;
  // background-color: #4CAF50; /* Green */
  // background-color: #1DF9BA;
  background-color: #05c790;
  border: none;
  cursor: pointer;
  color:white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: Poppins;
  border-radius: 6px;
 }

button:hover {
  background-color: #1DF9BA;
 }

// button {
//   background-color: #4CAF50; /* Green */
//   border: none;
 
   
//  color: white;
//   padding: 15px 32px;
//  text-align: center;
 
//  text-decoration: none;
//   display: inline-block;
//  font-size: 16px;
 
//  } 

.launch img {
display: block;
margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1%;
}

.rockets {
      text-align: center;
    margin-bottom: 30px;
}

.description {
  margin-top: 5px;
  margin-bottom: 5px;   
}