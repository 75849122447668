@import "./modules/wrapper";
@import "./modules/content";
@import "./modules/launch";
@import "./modules/countdown";



// /* If the width of the screen is 1600px or less, switch to desktop view */
// @media screen and (max-width: 1600px) {

//     .wrapper {
//         background-size: 500px;
//         background-position: 120% 130%;
//     }

//     // .launch {
//     //     min-width: 270px;
//     // }

//     img {
//         width: 500px;
//     }

// }

// /* If the width of the screen is 1200px or less, switch to tablet view */
// @media screen and (max-width: 1200px) {

//     .wrapper {
//         background-size: 300px;
//         background-position: 110% 120%;
//     }

//     // .launch {
//     //     min-width: 270px;
//     // }

//     img {
//         width: 300px;
//     }

// }


/* Mobile view */
/* If the width of the screen is 840px or less, switch to mobile view */
@media screen and (max-width: 840px) {

    .wrapper {
        background-size: 0px;
    }

    .launch {
        min-width: 270px;
    }

    img {
        width: 270px;
    }

    /* Setting the flex direction of header as column without wrapping */
    header {
        flex-flow: column nowrap;
        padding-bottom: 20px;
    }
}
