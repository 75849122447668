@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

$ff-primary: 'Poppins', sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}

body {
  font-family: $ff-primary;
  // display: flex;
  align-items: center;
  justify-content: center;
}

// .wrapper-one {
//   padding: 10vh 15px;
// }

h1 {
  font-weight: 300;
  text-align: center;
  margin-bottom: 5px;

  span {
    display: block;
  }
}

.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__box {

    svg {
      position: relative;

      circle {
        transform: rotate(-90deg);
        transform-origin: center;
        fill: transparent;
      }
    }

    &_circles {
      position: relative;
    }

    &_left {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      font-size: 20px;
    }

    &_label {
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }
  }
}